import { initialState } from '../app/store/state';

export type AppState = typeof initialState;

export type AppConfig = {
  devMode: boolean;
  clientId: string;
  apiBasePath: string;
  apiKeys: {
    dev: string;
    test: string;
    prod: string;
  };
} & BankConfig &
  FormConfig;

export type BankConfig = {
  name: string;
  bankId: number;
  logoWidth: number;
  color: string;
  googleAnalytics: {
    test: string | null;
    production: string | null;
  };
  forceOverride: boolean;
  backgroundGradient: string[];
  reaktionId?: number | undefined;
  backgroundImage: string;
  topBar: { background: string; color: string };
  body: { headlineColor: string; bodyColor: string };
};

export type FormConfig = {
  texts: TextsConfig;
  form: FormQuestionsConfig;
  responseFormat: ResponseFormatObject;
};

export type FormQuestionsConfig = {
  sections: Sections;
  questions: Questions;
};

export type ResponseFormatObject = {
  [key: string]: ResponseFormatObject | ResponseFormatter;
};

export type ResponseFormatter = (values: Values, state: Values) => null | any;

export type Values = {
  [key: string]: any;
};

export type TextsConfig = {
  introTitle: string;
  introText: string[];
  introStartButton: string;
  introSkipButton: string;
  loginTitle: string;
  cprTitle: string;
  cprTextHeadline: string;
  cprTextBody: string;
  cprButtonText: string;
  cprErrorHeadline: string;
  cprErrorBody: string;
  formButtonGoBack: string;
  formButtonGoForward: string;
  formButtonSubmit: string;
  formPageIndicator: string;
  formPageIndicatorSeparator: string;
  doneTitle: string;
  doneText: string[];
  errorGeneric: string;
  errorBrowserNotSupported: string;
  errorValidationEmpty: string;
  errorValidationNoSelection: string;
  errorValidationMinimumSelection: string;
  errorValidationOverMaxLength: string;
  errorValidationUnderMinLength: string;
  errorValidationCharNotAllowed: string;
  errorValidationNotNumber: string;
  errorValidationOverMaxValue: string;
  errorValidationOverMaxValueByField: string;
  errorValidationGeneric: string;
  genericButtonWait: string;
  genericDropdownSelect: string;
  genericDropdownNoOptions: string;
  warningBeforeLeavingPage: string;
};

export type Sections = {
  [key: string]: Section;
};

export type Section = {
  key?: string;
  order: number;
  name: string;
};

export type Questions = {
  [key: string]: Question;
};

export type Question = {
  focused?: boolean;
  disabled?: boolean;
  key?: string;
  section?: string;
  type?:
    | 'shorttext'
    | 'longtext'
    | 'number'
    | 'radiobuttons'
    | 'checkboxes'
    | 'checkbox'
    | 'dropdown';
  label?: string;
  placeholder?: string;
  description?: string;
  required?: boolean | number;
  maxLength?: number;
  minLength?: number;
  maxValue?: number | string;
  pattern?: RegExp;
  multiselect?: boolean;
  countries?: boolean;
  dependencies?: QuestionDependencies | null;
  options?: QuestionOption[] | string[];
};

export type QuestionDependencies = {
  type: 'every' | 'some';
  conditions: {
    [key: string]: QuestionCondition;
  };
};

export type QuestionCondition = {
  expression?: 'equals' | 'not_equals' | 'includes' | 'not_includes';
  value?: string | string[] | number[];
};

export type QuestionOption = {
  value: string | number;
  label: string;
  description?: string;
};

export enum ValidationError {
  EMPTY,
  MINIMUM_SELECTION,
  NO_SELECTION,
  NOT_NUMBER,
  OVER_MAX_LENGTH,
  OVER_MAX_VALUE,
  OVER_MAX_VALUE_BY_FIELD,
  UNDER_MIN_LENGTH,
  CHAR_NOT_ALLOWED
}

export type ValidationResult =
  | { valid: true }
  | { valid: false; error: ValidationError };

export type FormState = {
  values: Values;
};
