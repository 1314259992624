import React from 'react';
import config from '../../config';
import { H2 } from '@wco/toolkit';
import styled from 'styled-components';

const StyledH2 = styled(H2)`
  color: ${config.body.headlineColor};
`;

export default function DonePage() {
  return (
    <>
      <StyledH2>{config.texts.doneTitle}</StyledH2>
      {config.texts.doneText.map((text, i) => (
        <p key={`text${i}`}>{text}</p>
      ))}
    </>
  );
}
