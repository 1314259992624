import { FormConfig } from '../typings/types';

let formConfig = {} as FormConfig;

if (process.env.REACT_APP_FORM === 'standard') {
  formConfig = require('./form.standard').default;
}
if (process.env.REACT_APP_FORM === 'djurslandsbank') {
  formConfig = require('./form.djurslandsbank').default;
}
if (process.env.REACT_APP_FORM === 'kreditbanken') {
  formConfig = require('./form.kreditbanken').default;
}
if (process.env.REACT_APP_FORM === 'sydbank') {
  formConfig = require('./form.sydbank').default;
}
if (process.env.REACT_APP_FORM === 'nordfyns') {
  formConfig = require('./form.nordfyns').default;
}
if (process.env.REACT_APP_FORM === 'spks') {
  formConfig = require('./form.spks').default;
}
if (process.env.REACT_APP_FORM === 'skjernbank') {
  formConfig = require('./form.skjernbank').default;
}

export default formConfig;
