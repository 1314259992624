import styled from 'styled-components';

type ThemeProps = {
  warning?: boolean;
  marginVertical?: boolean;
};

const ErrorBox = styled.div<ThemeProps>`
  background: ${props => (props.warning ? '#ece4bd' : '#cc4747')};
  border: 1px solid ${props => (props.warning ? '#b2b360' : '#a01c1a')};
  color: ${props => (props.warning ? '#000' : '#fff')};
  border-radius: 3px;
  margin: ${props => (props.marginVertical ? '20px 0' : '5px 0 0')};
  padding: 6px 10px;
`;

export default ErrorBox;
