import React from 'react';
import styled from 'styled-components';
import responsive from '../styles/responsive';
import config from '../../config';
const PageIndicatorSpan = styled.span`
  position: absolute;
  bottom: 44px;
  right: 90px;
  font-size: 1.1rem;
  opacity: 0.8;
  color: ${config.body.bodyColor};
  ${responsive.medium} {
    position: static;
    display: block;
    margin-top: 15px;
    text-align: center;
  }
`;

type Props = {
  textPage: string;
  textOf: string;
  pageCurrent: number;
  pagesTotal: number;
};

const PageIndicator = (props: Props) => (
  <PageIndicatorSpan>
    {props.textPage} {props.pageCurrent} {props.textOf} {props.pagesTotal}
  </PageIndicatorSpan>
);

export default PageIndicator;
