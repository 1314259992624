import styled from 'styled-components';
import responsive from '../styles/responsive';

const LoginIframe = styled.iframe`
  width: 100%;
  max-width: 350px;
  height: 400px;

  ${responsive.small} {
    height: 300px;
  }
`;

export default LoginIframe;
