import axios from 'axios';

import { theme } from '@wco/toolkit';
import bankConfig from '../../configs';
import appConfig from '../../config';

const postSubmission = async (result: any): Promise<boolean> => {
  try {
    const urlStart =
      process.env.REACT_APP_ENV !== 'P' ? 'https://test-api.' : 'https://api.';
    const url = urlStart + theme.root + '/hfa/formaalomfang/submit';
    const apiKey =
      process.env.REACT_APP_ENV !== 'P'
        ? appConfig.apiKeys.test
        : appConfig.apiKeys.prod;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('EidToken')}`,
      'x-api-key': apiKey
    };

    const params = {
      forceOverride: `${bankConfig.forceOverride}`
    };

    if (process.env.REACT_APP_BANK === 'sydbank') {
      result['reactionType'] = bankConfig.reaktionId;
    }
    const response = await axios.post(url, JSON.stringify(result), {
      headers,
      params
    });
    const acceptedStatus = [201, 202];
    if (acceptedStatus.includes(response.status)) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export default {
  postSubmission
};
