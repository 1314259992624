import _ from 'lodash';
import {
  ResponseFormatObject,
  Values,
  Question,
  AppConfig,
  QuestionOption,
  FormQuestionsConfig,
  QuestionDependencies,
  ValidationError,
  ValidationResult,
  FormState,
  AppState,
  Section
} from '../../typings/types';

const countries = require('../data/countries');

export const questionsAreValid = (
  questions: Question[],
  formState: FormState
): boolean => {
  return _(questions)
    .map(q => validateQuestion(q, formState))
    .value()
    .every(q => q.valid);
};

export const validateQuestion = (
  question: Question,
  formState: FormState
): ValidationResult => {
  const fieldValue = formState.values[question.key as string];
  const valid = validateFieldValue(fieldValue, question, formState.values);
  if (valid !== true) {
    return {
      valid: false,
      error: valid
    };
  }
  return {
    valid: true
  };
};

export const validateFieldValue = (
  value: string | string[],
  question: Question,
  formStateValues: Values
): true | ValidationError => {
  if (question.required) {
    // Check empty string if trimmed
    if (_.isString(value) && _.isEmpty(value.trim())) {
      return ValidationError.EMPTY;
    }
    // Check array values
    if (_.isArray(value)) {
      // Check minimum selection number
      if (_.isNumber(question.required) && value.length < question.required) {
        return ValidationError.MINIMUM_SELECTION;
      }
    }
    // Check if empty
    if (_.isEmpty(value)) {
      return ValidationError.NO_SELECTION;
    }
  }

  if (question.type === 'number' && _.isString(value)) {
    if (!/^\d+$/.test(value)) {
      return ValidationError.NOT_NUMBER;
    }
  }

  if (question.maxLength && value.length > question.maxLength) {
    return ValidationError.OVER_MAX_LENGTH;
  }

  if (
    question.minLength &&
    _.isString(value) &&
    value.replace(/ /g, '').length < question.minLength
  ) {
    return ValidationError.UNDER_MIN_LENGTH;
  }

  if (question.pattern !== undefined && _.isString(value)) {
    if (!question.pattern.test(value.replace(/ /g, ''))) {
      return ValidationError.CHAR_NOT_ALLOWED;
    }
  }
  if (question.maxValue) {
    if (_.isInteger(question.maxValue) && value > question.maxValue) {
      return ValidationError.OVER_MAX_VALUE;
    }

    if (_.isString(question.maxValue)) {
      let maxValue = formStateValues[question.maxValue] || 0;
      if (_.isArray(maxValue) && maxValue.length >= 1) {
        maxValue = maxValue[0];
      }
      if (!_.isInteger(maxValue)) {
        maxValue = parseInt(maxValue);
      }
      if (value > maxValue) {
        return ValidationError.OVER_MAX_VALUE_BY_FIELD;
      }
    }
  }

  return true;
};

export const shouldQuestionBeShown = (
  question: Question,
  form: FormQuestionsConfig,
  formState: FormState
): boolean => {
  if (question.disabled) {
    return false;
  }

  if (!question.dependencies) {
    return true;
  }

  const {
    type = 'every',
    conditions
  }: QuestionDependencies = question.dependencies;

  return _[type](getKeyedArray(conditions), (c: any) => {
    const formValue = formState.values[c.key];
    const parentShouldBeShown = shouldQuestionBeShown(
      form.questions[c.key],
      form,
      formState
    );

    if (_.isEmpty(formValue) || !parentShouldBeShown) {
      return false;
    }

    switch (c.expression) {
      default:
      case 'equals':
        return formValue === c.value;
      case 'not_equals':
        return formValue !== c.value;
      case 'includes':
        return (
          _.isArray(formValue) &&
          _.every(c.value.map((v: string) => formValue.includes(v)), Boolean)
        );
      case 'not_includes':
        return (
          _.isArray(formValue) &&
          _.every(c.value.map((v: string) => !formValue.includes(v)), Boolean)
        );
    }
  });
};

export const setFormStateProps = (
  q: Question,
  option?: QuestionOption
): object => ({
  name: q.key,
  value: option && (option.value || option),
  validateOnBlur: false
});

export const setAdditionalInputProps = (
  q: Question,
  config: AppConfig
): object => ({
  focused: q.focused,
  placeholder: q.placeholder && q.placeholder.replace(/{name}/g, config.name),
  maxLength: q.maxLength,
  minLength: q.minLength,
  pattern: q.pattern
});

export const mapOptions = (options: any) =>
  options.map((option: any) =>
    _.isObject(option)
      ? option
      : {
          value: option,
          label: option
        }
  );

export const getCountries = (): Array<{ value: string; label: string }> =>
  _(countries)
    .keys()
    .map(key => ({
      value: key,
      label: countries[key]
    }))
    .sortBy('label')
    .value();

export const handleDropdownOnChange = (
  selected: { value: string; label: string }[],
  callback: any
) => {
  callback({
    target: {
      value: null,
      validity: {
        valid: true
      },
      options: selected.map(i => ({
        selected: true,
        value: i.value
      }))
    }
  });
};

export const makeResponse = (
  responseFormat: ResponseFormatObject,
  values: Values,
  appState: AppState
): Values => {
  const result = iterateResponseFormatObject(responseFormat, values, appState);
  const compact = removeEmptyObjects(result);
  return compact;
};

const iterateResponseFormatObject = (
  object: ResponseFormatObject,
  values: Values,
  appState: AppState
): Values =>
  _.reduce(
    object,
    (result, item, key) => {
      if (typeof item === 'object') {
        return {
          ...result,
          [key]: iterateResponseFormatObject(item, values, appState)
        };
      }

      try {
        const itemValue = item(values, appState);
        // if (itemValue !== null) {
        return {
          ...result,
          [key]: itemValue
        };
        // }
      } catch {}

      return {
        ...result
      };
    },
    {}
  );

export const removeEmptyObjects = (obj: Object): Values => {
  if (_.isArray(obj)) {
    return _(obj)
      .filter(_.isObject)
      .map(removeEmptyObjects)
      .reject(_.isEmpty)
      .concat(_.reject(obj, _.isObject))
      .value();
  }

  return _(obj)
    .pickBy(_.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(_.omitBy(obj, _.isObject))
    .value();
};

export const parseTokenFromUrl = (url: string): boolean | string => {
  if (url) {
    const regex = /token=(.*)$/;
    const match = regex.exec(url);
    if (match !== null && match.length > 1) {
      return match[1];
    }
  }
  return false;
};

export const getKeyedArray = <T>(data: object): T[] =>
  _(data)
    .map((v: any, key: string) => ({ ...v, key } as T))
    .sortBy('order')
    .value();

export const mergeObjects = <T>(orignal: T, custom: T): T =>
  _.mergeWith({}, orignal, custom, (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return srcValue;
    }
  });

export const sectionsFromUrlWithDefault = (sections: Section[]): Section[] => {
  const url = new URL(window.location.href);
  const sectionsParams = url.searchParams.get('p');
  if (!sectionsParams) {
    return sections;
  }

  const newSections = [] as Section[];
  const incomingIds = sectionsParams
    .split('-')
    .filter(s => s !== '' && +s >= 0)
    .map(Number);
  const allowedIds = sections.map(s => s.order);
  const finalIds = _.intersection(incomingIds, allowedIds);

  if (finalIds.length > 0) {
    finalIds.forEach(sectionId => {
      newSections.push(sections[sectionId]);
    });
  }
  return newSections;
};

export const getCorrID = () => {
  let corrID = sessionStorage.getItem('corrid');
  if (corrID) return corrID;
  corrID = uuidv4();
  sessionStorage.setItem('corrid', corrID);
  return corrID;
};

const uuidv4 = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
