import styled from 'styled-components';

const Description = styled.p`
  display: block;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: ${props => props.theme.space.medium};
  padding: 0;
  opacity: 0.8;
`;

export default Description;
