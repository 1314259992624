import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  color: red;
`;

function Asterix() {
  return <Span>*</Span>;
}

export default Asterix;
