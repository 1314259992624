import React, { useContext, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { utils } from '../common';
import { Question, Section } from '../../typings/types';
import { AppContext } from '../store';
import config from '../../config';
import api from '../services/api';
import {
  Asterix,
  ButtonsRow,
  Description,
  InputRenderer,
  InputValidator,
  Label,
  PageIndicator,
  Row
} from '../components';
import { Button, H2, Alert } from '@wco/toolkit';
import styled, { css } from 'styled-components';

const StyledH2 = styled(H2)`
  color: ${config.body.headlineColor};
`;

const ForwardButton = styled(Button)`
  height: 3rem;
  margin-left: 0.5rem;

  ${() =>
    process.env.REACT_APP_BANK === 'jyskebank' &&
    css`
      background-color: ${props => props.theme.colors.palette.lightGrey};
      color: ${props => props.theme.colors.palette.black};
      :hover {
        color: ${props => props.theme.colors.palette.green};

        background-color: ${props => props.theme.colors.palette.lightGrey};
      }
    `}
`;
const BackButton = styled(Button)`
  height: 3rem;
  margin-right: 0.5rem;

  ${() =>
    process.env.REACT_APP_BANK === 'jyskebank' &&
    css`
      background-color: #${props => props.theme.colors.palette.yellow};
      color: ${props => props.theme.colors.palette.black};
      border-color: #b7b7b7;
      margin-right: 4;
      :hover {
        color: ${props => props.theme.colors.palette.green};
        background-color: #${props => props.theme.colors.palette.yellow};
      }
    `}
`;

export default function FormPage() {
  const { appState, dispatch } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);

  const [formState, input] = useFormState(
    {},
    {
      withIds: true,
      onChange() {
        dispatch({ type: 'setFormIsDirty', payload: true });
      }
    }
  );

  const { form, responseFormat } = config;
  const sections = utils.sectionsFromUrlWithDefault(
    utils.getKeyedArray<Section>(form.sections)
  );

  const section: Section = sections[appState.sectionIndex];
  const questions = utils
    .getKeyedArray<Question>(form.questions)
    .filter(
      q =>
        q.section === section.key &&
        utils.shouldQuestionBeShown(q, form, formState)
    );
  const hasNextPage = appState.sectionIndex < sections.length - 1;
  const hasPrevPage = appState.sectionIndex > 0;

  async function submitHandler(event: React.FormEvent) {
    event.preventDefault();

    if (isSubmitted) {
      return;
    }

    const valid = utils.questionsAreValid(questions, formState);
    if (!valid) {
      dispatch({
        type: 'showFormErrors',
        payload: questions.map(q => q.key as string)
      });
      return;
    }

    if (hasNextPage) {
      dispatch({ type: 'formGoForward' });
      return;
    }
    // Success! Form is done!
    setIsSubmitted(true);
    // POST values to API
    const result = utils.makeResponse(
      responseFormat,
      formState.values,
      appState
    );

    const postSuccess = await api.postSubmission(result);
    if (postSuccess) {
      dispatch({ type: 'formDone' });
    } else {
      setSubmitError(true);
      setIsSubmitted(false);
    }
  }

  function backHandler(event: React.FormEvent) {
    event.preventDefault();
    dispatch({ type: 'formGoBack' });
  }
  return (
    <form>
      {isSubmitError && (
        <Alert
          severity="warning"
          headline={'Der opstod en fejl ved indsendelse af formularen.'}
        >
          {config.texts.errorGeneric}
        </Alert>
      )}
      <StyledH2>{section.name}</StyledH2>

      {questions.map(question => (
        <Row key={question.key}>
          <Label {...input.label(question.key ? question.key : '')}>
            {question.label} {question.required && <Asterix />}
          </Label>
          {question.description && (
            <Description>{question.description}</Description>
          )}
          <InputRenderer
            question={question}
            formState={formState}
            input={input}
          />
          <InputValidator
            question={question}
            formState={formState}
            appState={appState}
          />
        </Row>
      ))}

      <ButtonsRow>
        {hasPrevPage && !isSubmitted && (
          <BackButton
            marginHorizontal
            disabled={isSubmitted}
            onClick={backHandler}
          >
            {config.texts.formButtonGoBack}
          </BackButton>
        )}
        <ForwardButton
          type="submit"
          loading={isSubmitted}
          marginHorizontal
          disabled={isSubmitted}
          onClick={submitHandler}
        >
          {hasNextPage
            ? config.texts.formButtonGoForward
            : config.texts.formButtonSubmit}
        </ForwardButton>
      </ButtonsRow>

      <PageIndicator
        textPage={config.texts.formPageIndicator}
        textOf={config.texts.formPageIndicatorSeparator}
        pageCurrent={appState.sectionIndex + 1}
        pagesTotal={sections.length}
      />
    </form>
  );
}
