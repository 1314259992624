import React from 'react';
import Select from './Select';
import _ from 'lodash';
import { utils } from '../common';
import config from '../../config';
// Inputs
import TextInput from './TextInput';
import TextArea from './TextArea';
import OptionLabel from './OptionLabel';
import OptionDescription from './OptionDescription';

export default function InputRenderer({ question: q, formState, input }: any) {
  switch (q.type) {
    case 'shorttext':
    case 'number':
      return (
        <TextInput
          {...input.text(utils.setFormStateProps(q))}
          {...utils.setAdditionalInputProps(q, config)}
        />
      );

    case 'longtext':
      return (
        <TextArea
          {...input.textarea(utils.setFormStateProps(q))}
          {...utils.setAdditionalInputProps(q, config)}
        />
      );

    case 'checkboxes':
      return utils.mapOptions(q.options).map((option: any) => (
        <div key={option.value}>
          <OptionLabel
            boxed
            {...input.label(q.key, option.value)}
            className={q.options.length > 5 && 'half'}
          >
            <input {...input.checkbox(utils.setFormStateProps(q, option))} />
            <span>{option.label}</span>
            {option.description && (
              <OptionDescription>{option.description}</OptionDescription>
            )}
          </OptionLabel>
        </div>
      ));

    case 'checkbox':
      return (
        <OptionLabel boxed {...input.label(q.key)}>
          <input {...input.checkbox(utils.setFormStateProps(q))} />
          <span>{q.label}</span>
        </OptionLabel>
      );

    case 'dropdown':
      const handler = input.selectMultiple(utils.setFormStateProps(q));
      type valueType = { value: string; label: string } | null;

      const onChange = (value: valueType) => {
        utils.handleDropdownOnChange(
          value ? (_.isArray(value) ? value : [value]) : [],
          handler.onChange
        );
      };
      const options = q.countries
        ? utils.getCountries()
        : utils.mapOptions(q.options);
      const selectedOptions = (formState.values[q.key] || []).map((v: string) =>
        options.find((o: any) => v === o.value)
      );

      return (
        <Select
          autoFocus={q.focused}
          options={options}
          defaultValue={selectedOptions}
          onChange={onChange}
          placeholder={config.texts.genericDropdownSelect}
          isMulti={q.multiselect}
          isSearchable={q.countries === true}
          closeMenuOnSelect={!q.multiselect}
          noOptionsMessage={() => config.texts.genericDropdownNoOptions}
        />
      );

    case 'radiobuttons':
      return utils.mapOptions(q.options).map((option: any) => (
        <div key={option.value}>
          <OptionLabel
            boxed
            key={option.value}
            {...input.label(q.key, option.value)}
          >
            <input {...input.radio(utils.setFormStateProps(q, option))} />
            <span>{option.label}</span>
          </OptionLabel>
        </div>
      ));

    default:
      return null;
  }
}
