import React, { useContext } from 'react';
import { ErrorBox } from '../components';
import { userAgent } from '../common';
import { AppContext } from '../store';
import config from '../../config';
import { Button, H2 } from '@wco/toolkit';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const StyledH2 = styled(H2)`
  color: ${config.body.headlineColor};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;
const StyledButton = styled(Button)`
  height: 3rem;
  ${() =>
    process.env.REACT_APP_BANK === 'jyskebank' &&
    css`
      background-color: white;
      color: black;

      :hover {
        color: ${props => props.theme.colors.palette.green};
        background-color: ${props => props.theme.colors.palette.lightGrey};
      }
    `}
`;

export default function StartPage() {
  const { dispatch } = useContext(AppContext);

  function skipLogin(event: React.FormEvent) {
    event.preventDefault();
    dispatch({ type: 'loginSuccess' });
  }

  return (
    <>
      <StyledH2>{config.texts.introTitle}</StyledH2>
      {config.texts.introText.map((text, i) => (
        <p key={`text${i}`}>{text}</p>
      ))}
      {userAgent.isIE ? (
        <ErrorBox warning marginVertical>
          {config.texts.errorBrowserNotSupported}
        </ErrorBox>
      ) : (
        <ButtonWrapper>
          <Link to="/questions">
            <StyledButton focused={true}>
              {config.texts.introStartButton}
            </StyledButton>
          </Link>
        </ButtonWrapper>
      )}
      {config.devMode && (
        <ButtonWrapper>
          <StyledButton variant="outlined" onClick={skipLogin}>
            {config.texts.introSkipButton}
          </StyledButton>
        </ButtonWrapper>
      )}
    </>
  );
}
