import { BankConfig } from '../typings/types';

let bankConfig = {} as BankConfig;
if (process.env.REACT_APP_BANK === 'almbrand') {
  bankConfig = require('./config.almbrand').default;
}
if (process.env.REACT_APP_BANK === 'djurslandsbank') {
  bankConfig = require('./config.djurslandsbank').default;
}
if (process.env.REACT_APP_BANK === 'jyskebank') {
  bankConfig = require('./config.jyskebank').default;
}
if (process.env.REACT_APP_BANK === 'kreditbanken') {
  bankConfig = require('./config.kreditbanken').default;
}
if (process.env.REACT_APP_BANK === 'landbobanken') {
  bankConfig = require('./config.landbobanken').default;
}
if (process.env.REACT_APP_BANK === 'nordfyns') {
  bankConfig = require('./config.nordfyns').default;
}
if (process.env.REACT_APP_BANK === 'nordjyskebank') {
  bankConfig = require('./config.nordjyskebank').default;
}
if (process.env.REACT_APP_BANK === 'skjernbank') {
  bankConfig = require('./config.skjernbank').default;
}
if (process.env.REACT_APP_BANK === 'spks') {
  bankConfig = require('./config.spks').default;
}
if (process.env.REACT_APP_BANK === 'sydbank') {
  bankConfig = require('./config.sydbank').default;
}

export default bankConfig;
