import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,
  form: {
    ...StandardForm.form
  }
};

export default mergeObjects(StandardForm, customizations);
