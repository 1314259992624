import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Sparekassen Sjælland-Fyn',
  bankId: 42,
  logoWidth: 180,
  color: '#3fa43c',
  googleAnalytics: {
    test: 'UA-53256967-11',
    production: 'UA-53256967-6'
  },
  forceOverride: false,
  backgroundGradient: ['#f5f5f5', '#f5f5f5'],
  backgroundImage: '42_bg.jpg',
  topBar: { background: '#fff', color: '#424242' },
  body: { headlineColor: '#424242', bodyColor: '#424242' }
};

export default config;
