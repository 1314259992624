import { FormConfig } from '../typings/types';

const form: FormConfig = {
  texts: {
    introTitle: 'Formål & Omfang',
    introText: [
      'For at vi kan overholde lovgivningen, i dette tilfælde loven vedrørende hvidvask af penge, er det nødvendigt, at vi modtager og opbevarer information om forskellige økonomiske og personlige forhold fra alle kunder. Den information vi har brug for, fremgår af dette spørgeskema. Dine svar påvirker ikke prisen på dine ydelser.',
      'Spørgeskemaet er inddelt i 5 sider: Kundeforhold, indtægt og formue, kontanter, udland og PEP (Politisk eksponeret person).',
      'Du skal logge ind med MitID for at starte spørgeskemaet. Besvarelsen af spørgsmål på de næste sider foregår i et sikkert miljø. Du er til enhver tid velkommen til at kontakte din rådgiver, hvis du er i tvivl ved et spørgsmål.',
      'Når du starter spørgeskemaet, accepterer du samtidig, at denne side gør brug af cookies ifm. indsamling af dine indtastede oplysninger.'
    ],
    introStartButton: 'Log ind og start spørgeskema »',
    introSkipButton: 'Midlertidig: Skip login',
    loginTitle: 'Log ind med MitID',
    cprTitle: 'CPR nummer',
    cprTextHeadline: 'Indtast venligst dit CPR-nummer.',
    cprTextBody:
      'Du skal kun skrive tal i feltet. Indtast ikke mellemrum eller bindestreger.',
    cprButtonText: 'Fortsæt »',
    cprErrorHeadline: 'Der opstod en fejl ved validering af CPR-nummeret.',
    cprErrorBody:
      'Tjek venligst at CPR-nummeret er indtastet korrekt (10 tal, ingen tegn eller mellemrum), og at det er det samme CPR-nummer, som du benyttede ved MitID-login.',
    formButtonGoBack: '« Tilbage',
    formButtonGoForward: 'Videre »',
    formButtonSubmit: 'Indsend svar »',
    formPageIndicator: 'Side',
    formPageIndicatorSeparator: 'af',
    doneTitle: 'Tak for dine besvarelser!',
    doneText: [
      'Vi har nu registreret dine oplysninger, og du kan lukke dette vindue.',
      'Du kan opleve at blive kontaktet af din rådgiver, hvis vi har uddybende spørgsmål.'
    ],
    errorGeneric: 'Der opstod en fejl. Prøv igen senere.',
    errorBrowserNotSupported:
      'Vi kan se, at du bruger en browser, som vi desværre ikke understøtter. Benyt venligst en anden browser, eksempelvis Google Chrome, Firefox eller Safari. Alternativt kan du bruge en smartphone eller tablet.<br />Du er naturligvis også altid velkommen til at kontakte din rådgiver for at få en snak om, hvordan du forventer at bruge banken.',
    errorValidationEmpty: 'Feltet skal udfyldes.',
    errorValidationGeneric: 'Fejl.',
    errorValidationNoSelection: 'Du skal vælge en mulighed.',
    errorValidationMinimumSelection: 'Du skal vælge mindst $1 mulighed.',
    errorValidationOverMaxLength: 'Du må maximalt indtaste $1 tegn.',
    errorValidationNotNumber: 'Angiv kun tal i feltet.',
    errorValidationUnderMinLength: 'Du skal minimum indtaste $1 tegn',
    errorValidationCharNotAllowed: 'Du har brugt ugyldige karakterer.',
    errorValidationOverMaxValue:
      'Den indtastede værdi overstiger den maksimale grænse.',
    errorValidationOverMaxValueByField:
      'Den indtastede værdi overstiger beløbet, du indtastede tidligere.',
    genericButtonWait: 'Vent venligst...',
    genericDropdownSelect: 'Vælg ...',
    genericDropdownNoOptions: 'Ingen muligheder.',
    warningBeforeLeavingPage:
      'Alle indtastede data vil blive tabt hvis der navigeres væk fra denne side.'
  },
  form: {
    sections: {
      engagement: {
        order: 0,
        name: 'Kundeforhold'
      },
      income: {
        order: 1,
        name: 'Indtægt og formue'
      },
      cash: {
        order: 2,
        name: 'Kontanter'
      },
      abroad: {
        order: 3,
        name: 'Udland'
      },
      pep: {
        order: 4,
        name: 'Politisk eksponeret person (PEP)'
      }
    },
    questions: {
      purposeOfCustomerRelationship: {
        focused: true,
        section: 'engagement',
        type: 'longtext',
        label: 'Hvad er dit formål med at være kunde i banken?',
        placeholder: 'Hvad bruger du {name} til?',
        required: true
      },
      customerEngagementOtherBanks: {
        section: 'engagement',
        type: 'radiobuttons',
        label: 'Har du engagement i andre pengeinstitutter?',
        required: true,
        options: [
          {
            value: 'partial',
            label: 'Ja'
          },
          {
            value: 'full',
            label: 'Nej'
          }
        ]
      },
      customerEngagementComment: {
        section: 'engagement',
        type: 'longtext',
        label: 'Beskriv baggrunden for valg af flere banker',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            customerEngagementOtherBanks: {
              expression: 'equals',
              value: 'partial'
            }
          }
        }
      },
      // customerEngagementDenmarkComment: {
      //   section: 'engagement',
      //   type: 'longtext',
      //   label: 'Beskriv baggrunden for valg af flere banker',
      //   required: false,
      //   dependencies: {
      //     type: 'every',
      //     conditions: {
      //       customerEngagementLocations: {
      //         expression: 'includes',
      //         value: ['DK']
      //       }
      //     }
      //   }
      // },
      // customerEngagementAbroadCountries: {
      //   section: 'engagement',
      //   type: 'dropdown',
      //   label: 'Angiv hvilke lande udover Danmark',
      //   required: 1,
      //   multiselect: true,
      //   countries: true,
      //   dependencies: {
      //     type: 'every',
      //     conditions: {
      //       customerEngagementLocations: {
      //         expression: 'includes',
      //         value: ['Abroad']
      //       }
      //     }
      //   }
      // },
      // customerEngagementAbroadComment: {
      //   section: 'engagement',
      //   type: 'longtext',
      //   label: 'Beskriv baggrunden for valg af udenlandsk bank',
      //   required: false,
      //   dependencies: {
      //     type: 'every',
      //     conditions: {
      //       customerEngagementLocations: {
      //         expression: 'includes',
      //         value: ['Abroad']
      //       }
      //     }
      //   }
      // },
      employmentType: {
        focused: true,
        section: 'income',
        type: 'dropdown',
        label: 'Ansættelsesforhold',
        required: true,
        options: [
          'Ansat',
          'Selvstændig',
          'Ledig/Jobsøgende',
          'Pensioneret',
          'Studerende',
          'Andet'
        ]
      },
      employmentOtherComment: {
        section: 'income',
        type: 'shorttext',
        label: 'Beskriv ansættelsesforhold',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            employmentType: {
              expression: 'includes',
              value: ['Andet']
            }
          }
        }
      },
      employer: {
        section: 'income',
        type: 'shorttext',
        label: 'Ansættelsessted',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            employmentType: {
              expression: 'includes',
              value: ['Ansat']
            }
          }
        }
      },
      jobTitle: {
        section: 'income',
        type: 'shorttext',
        label: 'Stillingsbetegnelse',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            employmentType: {
              expression: 'includes',
              value: ['Ansat']
            }
          }
        }
      },
      annualIncomeAmount: {
        section: 'income',
        type: 'dropdown',
        label: 'Årlig indtægt efter skat',
        required: true,
        options: [
          {
            value: 99999,
            label: '0 - 99.999'
          },
          {
            value: 249999,
            label: '100.000 - 249.999'
          },
          {
            value: 499999,
            label: '250.000 - 499.999'
          },
          {
            value: 749999,
            label: '500.000 - 749.999'
          },
          {
            value: 999999,
            label: '750.000 - 999.999'
          },
          {
            value: 1499999,
            label: '1.000.000 - 1.499.999'
          },
          {
            value: 1500000,
            label: '1.500.000 og derover'
          }
        ]
      },
      annualIncomeOther: {
        section: 'income',
        type: 'radiobuttons',
        label: 'Har du andre indtægter? F.eks. lejeindtægter, honorarer mv.',
        required: true,
        options: [
          {
            value: 'Yes',
            label: 'Ja'
          },
          {
            value: 'No',
            label: 'Nej'
          }
        ]
      },
      annualIncomeComment: {
        section: 'income',
        type: 'longtext',
        label: 'Beskriv hvilke andre indtægter',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            annualIncomeOther: {
              expression: 'equals',
              value: 'Yes'
            }
          }
        }
      },
      assetsAmount: {
        section: 'income',
        type: 'dropdown',
        label:
          'Hvor stor er din samlede formue placeret i kontanter, indestående på konti og værdipapirer?',
        description:
          'Angiv din samlede formue som summen af let omsættelige aktiver (kontanter, indestående på konti og værdipapirer i frit depot). Du skal ikke medregne friværdi i fast ejendom og bundne pensionsmidler.',
        required: true,
        options: [
          {
            value: 99999,
            label: '0 - 99.999'
          },
          {
            value: 499999,
            label: '100.000 - 499.999'
          },
          {
            value: 999999,
            label: '500.000 - 999.999'
          },
          {
            value: 1999999,
            label: '1.000.000 - 1.999.999'
          },
          {
            value: 4999999,
            label: '2.000.000 - 4.999.999'
          },
          {
            value: 9999999,
            label: '5.000.000 - 9.999.999'
          },
          {
            value: 19999999,
            label: '10.000.000 - 19.999.999'
          },
          {
            value: 49999999,
            label: '20.000.000 - 49.999.999'
          },
          {
            value: 50000000,
            label: '50.000.000 og derover'
          }
        ]
      },
      assetsAmountInPi: {
        section: 'income',
        type: 'number',
        label:
          'Hvor stor en del af din samlede formue, anført ovenfor, er placeret hos os? Angiv i hele tal',
        required: true,
        maxLength: 15,
        maxValue: 'assetsAmount'
      },
      assetsComment: {
        section: 'income',
        type: 'longtext',
        label: 'Hvor stammer din formue fra?',
        required: true
      },
      cashDepositAmount: {
        focused: true,
        section: 'cash',
        type: 'dropdown',
        label:
          'Hvor mange kontanter forventer du at indsætte årligt i automater eller bankens kasser?',
        required: true,
        options: [
          {
            value: 0,
            label: 'Ingen'
          },
          {
            value: 24999,
            label: '1 - 24.999'
          },
          {
            value: 49999,
            label: '25.000 - 49.999'
          },
          {
            value: 99999,
            label: '50.000 - 99.999'
          },
          {
            value: 100000,
            label: '100.000 og derover'
          }
        ]
      },
      cashDepositComment: {
        section: 'cash',
        type: 'longtext',
        label: 'Beskriv årsag til kontante indsættelser',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            cashDepositAmount: {
              expression: 'not_includes',
              value: [0]
            }
          }
        }
      },
      cashWithdrawalAmount: {
        section: 'cash',
        type: 'dropdown',
        label:
          'Hvor mange kontanter forventer du at hæve årligt i automater eller bankens kasser?',
        required: true,
        options: [
          {
            value: 0,
            label: 'Ingen'
          },
          {
            value: 49999,
            label: '1 - 49.999'
          },
          {
            value: 99999,
            label: '50.000 - 99.999'
          },
          {
            value: 199999,
            label: '100.000 - 199.999'
          },
          {
            value: 200000,
            label: '200.000 og derover'
          }
        ]
      },
      cashWithdrawalComment: {
        section: 'cash',
        type: 'longtext',
        label: 'Beskriv årsag til kontante hævninger',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            cashWithdrawalAmount: {
              expression: 'not_includes',
              value: [0]
            }
          }
        }
      },
      foreignAssociated: {
        section: 'abroad',
        type: 'radiobuttons',
        label:
          'Har du økonomisk tilknytning til andre lande end Danmark? F.eks. arbejde, bolig mv.',
        required: true,
        options: [
          {
            value: 'Yes',
            label: 'Ja'
          },
          {
            value: 'No',
            label: 'Nej'
          }
        ]
      },
      foreignAssociatedCountries: {
        section: 'abroad',
        type: 'dropdown',
        label: 'Vælg de lande du har økonomisk tilknytning til',
        required: 1,
        countries: true,
        multiselect: true,
        dependencies: {
          type: 'every',
          conditions: {
            foreignAssociated: {
              expression: 'equals',
              value: 'Yes'
            }
          }
        }
      },
      foreignAssociatedCountriesComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv tilknytning til udlandet',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            foreignAssociatedCountries: {
              expression: 'not_includes',
              value: ['']
            }
          }
        }
      },
      foreignReceiveAmount: {
        section: 'abroad',
        type: 'dropdown',
        label:
          'Hvor stort et beløb forventer du at få overført fra andre lande årligt, i form af konto-til-konto overførsler? ',
        required: true,
        options: [
          {
            value: 0,
            label: 'Ingen'
          },
          {
            value: 9999,
            label: '1 - 9.999'
          },
          {
            value: 49999,
            label: '10.000 - 49.999'
          },
          {
            value: 99999,
            label: '50.000 - 99.999'
          },
          {
            value: 249999,
            label: '100.000 - 249.999'
          },
          {
            value: 499999,
            label: '250.000 - 499.999'
          },
          {
            value: 500000,
            label: '500.000 og derover'
          }
        ]
      },
      foreignReceiveComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv årsag til modtagelse af udenlandske overførsler',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            foreignReceiveAmount: {
              expression: 'not_includes',
              value: [0]
            }
          }
        }
      },
      foreignTransferAmount: {
        section: 'abroad',
        type: 'dropdown',
        label:
          'Hvor stort et beløb forventer du at overføre til andre lande årligt, i form af konto-til-konto overførsler?',
        required: true,
        options: [
          {
            value: 0,
            label: 'Ingen'
          },
          {
            value: 49999,
            label: '1 - 49.999'
          },
          {
            value: 99999,
            label: '50.000 - 99.999'
          },
          {
            value: 199999,
            label: '100.000 - 199.999'
          },
          {
            value: 200000,
            label: '200.000 og derover'
          }
        ]
      },
      foreignTransferComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv årsag til udenlandske overførsler',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            foreignTransferAmount: {
              expression: 'not_includes',
              value: [0]
            }
          }
        }
      },
      pepStatement: {
        section: 'pep',
        type: 'checkboxes',
        label: 'Kryds venligst de felter af som passer på din situation',
        required: 1,
        options: [
          {
            value: 'Jeg er en PEP',
            label: 'Jeg er en PEP',
            description:
              "En politisk eksponeret person har eller har haft et af følgende højtstående erhverv: Statschef, regeringschef, minister, assisterende minister, medlemmer af Folketinget eller af Europa-Parlamentet, medlemmer af politiske partiers styrende organer, højesteretsdommere, medlemmer af forfatningsdomstole og andre højtstående retsinstanser, medlemmer af revisionsretter og øverste ledelseorganer for centralbanker, ambassadører, chargé d'affaires og højtstående officerer i de væbnede styrker, medlemmer af statsejede virksomheders administrative, ledende eller kontrollerende organer."
          },
          {
            value: 'Jeg er nærtstående til en PEP',
            label: 'Jeg er nærtstående til en PEP',
            description:
              'Nærtstående til en PEP er ægtefælle, registreret partner, samlever eller forældre samt børn og disses ægtefæller, registrerede partnere eller samlevere.'
          },
          {
            value: 'Jeg er samarbejdspartner til en PEP',
            label: 'Jeg er samarbejdspartner til en PEP',
            description:
              'En person, der har en nær forretningsmæssig forbindelse med en eller flere politisk eksponerede personer, via ejerskab af en virksomhed.'
          },
          {
            value: 'Jeg er ikke PEP',
            label: 'Jeg er ikke PEP'
          }
        ]
      }
    }
  },
  responseFormat: {
    cprNo: (values, state) => (state.cprNo ? state.cprNo : null),
    purposeAndScope: {
      schemaType: () => 'private',
      purposeOfCustomerRelationship: (values, state) => {
        if (!values.purposeOfCustomerRelationship) {
          return null;
        }
        return values.purposeOfCustomerRelationship;
      },
      customerEngagement: {
        type: values => {
          if (!values.customerEngagementOtherBanks) {
            return null;
          }
          return values.customerEngagementOtherBanks;
        },
        inDenmark: values => {
          return values.customerEngagementOtherBanks === 'partial';
        },
        inDenmarkComment: values => {
          if (values.customerEngagementOtherBanks === 'partial') {
            return values.customerEngagementComment;
          }
          return null;
        },
        abroad: () => {
          return false;
        },

        abroadComment: () => {
          return '';
        }
      },
      employer: values => {
        if (!values.employmentType) {
          return null;
        }
        return values.employmentType[0] === 'Ansat'
          ? values.employer
          : values.employmentType[0];
      },
      jobTitle: values =>
        values.jobTitle || values.employmentOtherComment || null,
      assets: {
        amount: values => values.assetsAmount[0],
        comment: values =>
          values.assetsComment
            ? values.assetsComment
            : 'Kunden er ikke blevet spurgt til stillingsbetegnelse.'
      },
      assetsAmountInPi: values =>
        values.assetsAmountInPi ? parseInt(values.assetsAmountInPi) : null,
      annualNetIncome: {
        amount: values => values.annualIncomeAmount[0],
        comment: values =>
          values.annualIncomeComment
            ? values.annualIncomeComment
            : 'Kunden har ikke andre indtægter.'
      },
      annualCashDeposit: {
        amount: values => values.cashDepositAmount[0],
        comment: values =>
          values.cashDepositComment
            ? values.cashDepositComment
            : 'Ingen kommentar fra kunde'
      },
      annualCashWithdrawal: {
        amount: values => values.cashWithdrawalAmount[0],
        comment: values =>
          values.cashWithdrawalComment
            ? values.cashWithdrawalComment
            : 'Ingen kommentar fra kunde'
      },
      transfersFromAbroad: {
        amount: values => values.foreignReceiveAmount[0],
        comment: values =>
          values.foreignReceiveComment
            ? values.foreignReceiveComment
            : 'Ingen kommentar fra kunde'
      },
      transfersAbroad: {
        amount: values => values.foreignTransferAmount[0],
        comment: values =>
          values.foreignTransferComment
            ? values.foreignTransferComment
            : 'Ingen kommentar fra kunde'
      },
      geographicalAssociation: {
        onlyDenmark: values =>
          values.foreignAssociated ? values.foreignAssociated === 'No' : null,
        otherCountries: values => {
          if (values.foreignAssociated === 'No') {
            return null;
          }
          if (
            !values.foreignAssociatedCountries ||
            values.foreignAssociatedCountries.length <= 0
          ) {
            return 'Kunden har ikke tilknytning til udlandet.';
          }
          return values.foreignAssociatedCountries;
        },
        comment: values => {
          if (values.foreignAssociated === 'No') {
            return null;
          }
          return values.foreignAssociatedCountriesComment
            ? values.foreignAssociatedCountriesComment
            : null;
        }
      }
    },
    politicallyExposedPerson: {
      isPep: values => {
        if (!values.pepStatement || values.pepStatement.length <= 0) {
          return null;
        }
        return !values.pepStatement.includes('Jeg er ikke PEP');
      },
      comments: values => (values.pepStatement ? values.pepStatement : null)
    }
  }
};

export default form;
