import { createGlobalStyle } from 'styled-components';
import config from '../../config';
export const GlobalStyles = createGlobalStyle`
  body {
 

    p { 
      color:${config.body.bodyColor} !important;
    }
    label {
      color:${config.body.bodyColor};
    }
  }
`;
