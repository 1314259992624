import styled from 'styled-components';
import css from '@styled-system/css';

const Container = styled.div`
  ${css({
    position: ['relative', 'relative', 'absolute']
  }) as any}
  top: 27%;
  width: 100%;
  color: #111;
`;

export default Container;
