import bankConfig from './configs';
import formConfig from './forms';
import { AppConfig } from './typings/types';

const devMode = process.env.REACT_APP_ENV !== 'P';

const appConfig: AppConfig = {
  devMode,
  clientId: '0a99b4ea-b660-4abf-aadf-1118a04f158d',
  apiBasePath: devMode ? 'https://apis.bankdata.dk' : 'https://api.bankdata.dk',
  apiKeys: {
    dev: 'hfa-dev-eqtOmTSMCCdKAu7LoFsDiLY3PyeWPU4C',
    test: 'hfa-test-GlrfHZcmK4NDjIadgiJVwGISTMILrmkO',
    prod: '7mlFFMYgctDok2yyq56CAFPhfdtD5yWG'
  },
  ...bankConfig,
  ...formConfig
};

export default appConfig;
