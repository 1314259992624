import React, { useReducer } from 'react';
import { GlobalStyles } from './styles/globalStyles';
import { AppContext } from './store';
import { initialState } from './store/state';
import { reducer } from './store/reducer';
import css from '@styled-system/css';
import styled from 'styled-components';
import { Container } from './components';
import {
  LinkGenerator,
  LandingPage,
  FormPage,
  StartPage,
  DonePage
} from './pages';
import { AppState } from '../typings/types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import config from '../config';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle, Logo } from '@wco/toolkit';
import { EidPrivateRoute } from '@wco/login';

// Helper function to return the right page component
const getActiveComponent = (state: AppState) => {
  if (state.isDone) {
    return DonePage;
  }
  if (state.isLoggedIn) return FormPage;
  return StartPage;
};

const Header = styled.div`
  background-color: ${config.topBar.background};

  justify-content: center;
`;
const Banner = styled.div`
  margin: auto;
  max-width: 1240px;
  height: 67px;
  display: flex;
  align-items: center;
`;

const Filler = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  height: calc(100% - 80px);
`;

const InnerContainer = styled.div`
  position: relative;
  max-width: 1320px;
  height: auto;
  min-height: 450px;
  ${css({
    margin: ['0', '0', '40px auto'],
    marginBottom: ['0', '20px', '20px']
  }) as any}
`;

const InnerInnerContainer = styled.div`
  background-image: ${props =>
    `linear-gradient(${config.backgroundGradient[0]}, ${config.backgroundGradient[1]})`} !important;
  min-height: 390px;
  ${css({
    margin: ['0', '0 40px', '0 50px']
  }) as any}
  padding: 30px 40px;
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 15px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    left: 60px;
    right: auto;
    transform: rotate(-3deg);
    box-sizing: border-box;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 15px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    transform: rotate(3deg);
    right: 60px;
    left: auto;
    box-sizing: border-box;
  }
`;
const BackgroundImageContainer = styled.div`
  width: 100%;
  ${css({
    height: ['7.5rem', '12.23rem', '25rem']
  }) as any}
  overflow: hidden;
  background-color: ${() =>
    config.backgroundImage ? '' : config.topBar.background};
`;
const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const App = () => {
  const themeWithALTLogo = [
    'Landbobanken',
    'Nordjyskebank',
    'Skjernbank',
    'Djurslandsbank',
    'Jyskebank'
  ];
  // Setup state
  const [appState, dispatch] = useReducer(reducer, initialState);
  // Alert the user about page reload
  window.onbeforeunload = () => {
    if (appState.formIsDirty && !appState.isDone) {
      return config.texts.warningBeforeLeavingPage;
    }
  };

  const Component = getActiveComponent(appState);

  const redirectUrl =
    window.location.hostname === 'localhost'
      ? window.location.protocol +
        '//' +
        window.location.hostname +
        ':' +
        window.location.port +
        '/landing'
      : window.location.protocol + '//' + window.location.hostname + '/landing';

  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      <Wrapper>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <GlobalStyles />
          <Header>
            <Banner>
              <a href="./">
                {themeWithALTLogo.includes(theme.name) ? (
                  <Logo
                    altlogo={true}
                    style={{
                      height: '70px',
                      width: config.logoWidth,
                      marginLeft: '46px'
                    }}
                  />
                ) : theme.name === 'Nordfyns' ? (
                  <Logo
                    altlogo={false}
                    style={{
                      height: '70px',
                      width: config.logoWidth,
                      marginLeft: '46px'
                    }}
                    src="images/nordfynsbank-logo.svg"
                  />
                ) : (
                  <Logo
                    altlogo={false}
                    style={{
                      height: '70px',
                      width: config.logoWidth,
                      marginLeft: '46px'
                    }}
                  />
                )}
              </a>
              <Filler />
              {/* <Help>
								Har du brug for hjælp? Ring 7333 1705 alle dage kl. 10-16.
							</Help> */}
            </Banner>
          </Header>
          <BackgroundImageContainer>
            {config.backgroundImage && (
              <BackgroundImage
                src={'./' + config.backgroundImage}
              ></BackgroundImage>
            )}
          </BackgroundImageContainer>
          <Container>
            <InnerContainer>
              <InnerInnerContainer>
                <Router>
                  <Route path="/" exact>
                    <Component />
                  </Route>
                  <Route path="/landing" exact>
                    <LandingPage />
                  </Route>

                  <EidPrivateRoute
                    type="oces"
                    redirectUrl={redirectUrl}
                    path="/questions"
                    exact
                  >
                    {appState.isDone ? <DonePage /> : <FormPage />}
                  </EidPrivateRoute>
                  <Route path="/sider" exact component={LinkGenerator} />
                </Router>
              </InnerInnerContainer>
            </InnerContainer>
          </Container>
        </ThemeProvider>
      </Wrapper>
    </AppContext.Provider>
  );
};

export default App;
