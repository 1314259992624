import styled from 'styled-components';

const OptionDescription = styled.p`
  display: block;
  margin-top: ${props => props.theme.space.small};
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  color: #555;
`;

export default OptionDescription;
