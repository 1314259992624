import styled, { css as cssStyled } from 'styled-components';
import css from '@styled-system/css';

const Responsive = cssStyled(css({
  marginBottom: ['large', 'xlarge']
}) as any);

const Row = styled.div`
  ${Responsive}
`;

export default Row;
