import { Action } from './actions';
import { AppState } from '../../typings/types';

export const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'getLoginUriSuccess':
      return {
        ...state,
        loginUri: action.payload,
        showLogin: true
      };
    case 'loginSuccess':
      return {
        ...state,
        isLoggedIn: true
      };
    case 'formDone':
      return {
        ...state,
        isDone: true
      };
    case 'setToken':
      return {
        ...state,
        token: action.payload
      };
    case 'setCprNo':
      return {
        ...state,
        cprNo: action.payload
      };
    case 'setFormIsDirty':
      return {
        ...state,
        formIsDirty: action.payload
      };
    case 'showFormErrors':
      return {
        ...state,
        validatedQuestions: action.payload
      };
    case 'formGoBack':
      return {
        ...state,
        sectionIndex: state.sectionIndex - 1
      };
    case 'formGoForward':
      return {
        ...state,
        sectionIndex: state.sectionIndex + 1,
        validatedQuestions: []
      };
    default:
      return state;
  }
};
