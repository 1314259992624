import { css } from 'styled-components';

export default css`
  font-size: inherit;
  display: block;
  width: calc(100% - 17px);
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: ${props => props.theme.space.small};
  outline: none;
  line-height: 1.4;
  transition: all 100ms;
  -webkit-appearance: none;

  :hover {
    border-color: #b3b3b3;
  }

  :active,
  :focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;
