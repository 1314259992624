import React from 'react';
import { utils } from '../common';
import ErrorBox from './ErrorBox';
import {
  Question,
  FormState,
  AppState,
  ValidationError
} from '../../typings/types';
import config from '../../config';

type Props = {
  question: Question;
  formState: FormState;
  appState: AppState;
};

function getErrorMessage(
  errorCode: ValidationError,
  question: Question
): string {
  switch (errorCode) {
    case ValidationError.EMPTY:
      return config.texts.errorValidationEmpty;
    case ValidationError.NO_SELECTION:
      return config.texts.errorValidationNoSelection;
    case ValidationError.MINIMUM_SELECTION:
      return config.texts.errorValidationMinimumSelection.replace(
        '$1',
        question.required + ''
      );
    case ValidationError.OVER_MAX_LENGTH:
      return config.texts.errorValidationOverMaxLength.replace(
        '$1',
        question.maxLength + ''
      );
    case ValidationError.UNDER_MIN_LENGTH:
      return config.texts.errorValidationUnderMinLength.replace(
        '$1',
        question.minLength + ''
      );
    case ValidationError.CHAR_NOT_ALLOWED:
      return config.texts.errorValidationCharNotAllowed;
    case ValidationError.NOT_NUMBER:
      return config.texts.errorValidationNotNumber;
    case ValidationError.OVER_MAX_VALUE:
      return config.texts.errorValidationOverMaxValue;
    case ValidationError.OVER_MAX_VALUE_BY_FIELD:
      return config.texts.errorValidationOverMaxValueByField;
    default:
      return config.texts.errorValidationGeneric;
  }
}

export default function InputValidator({
  question,
  formState,
  appState
}: Props) {
  if (!question || !question.key) {
    return null;
  }

  const validated = appState.validatedQuestions.includes(question.key);
  if (!validated) {
    return null;
  }

  const validation = utils.validateQuestion(question, formState);
  if (validation.valid) {
    return null;
  }

  return <ErrorBox>{getErrorMessage(validation.error, question)}</ErrorBox>;
}
