export const initialState = {
  token: '',
  loginUri: '',
  cprNo: '',
  isLoggedIn: false,
  isDone: false,
  showLogin: false,
  validatedQuestions: [] as string[],
  formIsDirty: false,
  sectionIndex: 0
};
