import React, { useRef, useEffect } from 'react';

const focusable = (WrappedInputComponent: any) => (props: any) => {
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (props.focused) {
      inputRef.current.focus();
    }
  }, [props.focused]);

  return <WrappedInputComponent ref={inputRef} {...props} />;
};

export default focusable;
