import styled from 'styled-components';
import focusable from './focusable';

const BaseOptionLabel = styled.label`
  display: inline-block;
  margin-top: ${props => props.theme.space.small};
  margin-bottom: 0;
  min-width: 200px;
  padding: ${props => props.theme.space.medium};
  border-radius: 3px;
  border: 1px solid #ccc;
  transition: all 100ms;

  &:hover {
    cursor: pointer;
    border-color: #b3b3b3;
  }

  :active,
  :focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }

  &.half {
    float: left;
    width: 50%;
  }

  & input,
  & span {
    vertical-align: middle;
  }

  & span {
    display: inline-block;
    margin-left: ${props => props.theme.space.small};
    white-space: nowrap;
    line-height: 1;
  }
`;

const OptionLabel = focusable(BaseOptionLabel);

export default OptionLabel;
