import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import _ from 'lodash';
import config from '../../config';
import { TextInput, Row } from '../components';
import { utils } from '../common';
import { Section } from '../../typings/types';
import { Button, H2 } from '@wco/toolkit';
import styled from 'styled-components';

const StyledH2 = styled(H2)`
  color: ${config.body.headlineColor};
`;

export default function LinkGenerator() {
  const sectionsArray = utils.getKeyedArray<Section>(config.form.sections);

  const [copied, setCopied] = useState(false);
  const [selectedIds, setSelectedIds] = useState(
    sectionsArray.map(s => s.order)
  );

  const urlToCopy =
    window.location.origin +
    (selectedIds.length > 0 && selectedIds.length !== sectionsArray.length
      ? `?p=${selectedIds.join('-')}`
      : '');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const ids = value.split(',').map(Number);

    if (checked) {
      const newIds = [...selectedIds];
      newIds.push(ids[0]);
      setSelectedIds(newIds.sort());
    } else {
      const newIds = _.pull([...selectedIds], ids[0]);
      setSelectedIds(newIds);
    }
  };

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div>
      <StyledH2>Sidevælger</StyledH2>
      <p>Her kan du vælge de spørgeskema sider der skal vises</p>

      <Row>
        {sectionsArray.map(section => {
          const isChecked = selectedIds.indexOf(+section.order) >= 0;
          return (
            <div>
              <label style={{ padding: 12 }} key={section.name}>
                <input
                  id={section.key}
                  value={section.order}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                />
                <span style={{ paddingLeft: 12 }}>{section.name}</span>
              </label>
            </div>
          );
        })}
      </Row>

      <Row>
        <TextInput type="text" value={urlToCopy} disabled />
      </Row>

      <Row>
        <CopyToClipboard text={urlToCopy} onCopy={onCopy}>
          <Button>{copied ? 'Kopieret!' : 'Kopier'}/</Button>
        </CopyToClipboard>
      </Row>
    </div>
  );
}
